import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from "../components/Navbar/Navbar"
import UslugiPages from "../components/Uslugi/UslugiPages"
import {BoxTextPages, BigTitlePages,  Flex, FlexContent, TitleServices, ListServices} from '../components/Uslugi/UslugiStyled'
import { StaticImage }from "gatsby-plugin-image"


import { theme } from "../components/theme.js";
import { ThemeProvider } from "styled-components"


import SEO from "../components/seo"

const stomatologia = () => {
  return (

    <>
    < ThemeProvider theme={theme}>
    <SEO 
    title="Stomatologia"
    description="Oferujemy szeroki wachlarz usług weterynaryjnych dotyczących stomatologii psów, kotów, zwierząt futerkowych i gryzoni. 
     Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
     keywords="stomatolog zwierząt kalisz"
    />
    <Navbar />
    <BigTitlePages style={{justifyContent: 'center'}}
         whileInView={{y: 0, opacity:1}}
         initial={{ y:50, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >Stomatologia</BigTitlePages>  
         
         <FlexContent>
          <UslugiPages />
 <Flex>



    <BoxTextPages>

<TitleServices>Usługi z zakresu stomatologii:</TitleServices>
<ListServices> Korekcja zgryzu
</ListServices>
<ListServices> Usuwanie kamienia nazębnego metodą ultradźwiękowa
</ListServices>
<ListServices> Polerowanie zębów</ListServices>
<ListServices> Ekstrakcja zębów</ListServices>

    </BoxTextPages>
 <StaticImage
    src="../images/stomatologia.jpeg"
    alt="Stomatologia zwierząt Kalisz"
    width={800}
    placeholder="blurred"
    className='staticimagepages'
    />
 
    
</Flex>
</FlexContent>
<Footer />
</ThemeProvider>
    </>
  )
}

export default stomatologia